import { useState } from "react";
import { Link } from "react-router-dom";
import trailer from "../assets/portfolio/trailer-break-icon-380.webp";
import trailerCover from "../assets/portfolio/trailer-break-cover-1024.webp";
import charities from "../assets/portfolio/charitiesnearme-cover-1024.webp";
import charitiesIcon from "../assets/portfolio/charities-icon-380.webp";
import waf from "../assets/portfolio/woufipedia-cover-1024.webp";
import wafIcon from "../assets/portfolio/woufipedia-icon-380.webp";
import rightPrice from "../assets/portfolio/rightprice-cover-1024.webp";
import rightPriceIcon from "../assets/portfolio/rightprice-icon-380.webp";
import digitalpartner from "../assets/portfolio/digital-partner-cover-1024.webp";
import digitalpartnerIcon from "../assets/portfolio/digitalpartner-icon-380.webp";

const Portfolio = () => {
  const portfolioStock = [
    {
      icon: digitalpartnerIcon,
      cover: digitalpartner,
      title: "Digital-partner.co.uk",
      slogan: "Empowering the digital frontier",
      description: (
        <>
          <p>
            This platform combines my expertise in React for dynamic front-end
            experiences, Node.js and Express for robust backend operations, and
            MongoDB for seamless data handling.
          </p>
          <p>
            Explore my blog for in-depth discussions on web development, content
            creation, SEO, and email marketing.
          </p>
          <p>
            It's a testament to my dedication to the digital world, offering a
            responsive and informative experience for fellow enthusiasts and
            aspiring professionals.
          </p>
        </>
      ),
      tech: ["React", "Node", "Express", "MongoDB"],
      link: "https://digital-partner.co.uk",
    },
    {
      icon: wafIcon,
      cover: waf,
      title: "Woufipedia.com",
      slogan: "How to be the best human possible for your dog",
      description: (
        <>
          <p>
            Every post on my latest WordPress website has received an impressive
            Lighthouse report on mobile, with scores of 100 for SEO, 100 for
            Best Practices, 100 for Accessibility, and between 90 and 95 for
            Performance. On desktop, it achieves nearly 400!
          </p>
          <p>
            This website allows me to combine multiple passions: dogs, web
            development, content creation, keyword research, SEO, email
            marketing, and social media.
          </p>
          <p>
            It's in a highly competitive niche, but it also provides a wonderful
            opportunity to compete and learn from the best.
          </p>
        </>
      ),
      tech: ["Wordpress", "SASS", "ACF", "Aweber"],
      link: "https://woufipedia.com",
    },
    {
      icon: rightPriceIcon,
      cover: rightPrice,
      title: "Rightprice extension",
      slogan:
        "Connect Rightmove with HM Land Registry. Get latest property sales figures for your area, all in one place",
      description: (
        <>
          <p>
            This Chrome Extension made with React and Typescript was my final
            project for the CS50x course from HarvardX.
          </p>
          <p>
            It merges Rightmove listings with UK Land Registry data, empowering
            you with accurate property sale prices in your desired postcode.
          </p>
          <p>
            At first, it extracts property data from the Rightmove page, and
            prepares SPARQL queries to the HM Land Registry API.
          </p>
          <p>
            Then, it creates and inserts a responsive table into the page to
            display property sales data such as sale date, price, address,
            estate type, and whether it's a new build.
          </p>
        </>
      ),
      tech: ["REACT", "TYPESCRIPT", "CSS", "API"],
      link: "https://chrome.google.com/webstore/detail/rightprice/aokpcnjbgjgflnmcmacmfmaglihmiiea",
    },
    {
      icon: trailer,
      cover: trailerCover,
      title: "Trailer-break.com",
      slogan: "Want to know what to watch tonight?",
      description: (
        <>
          <p>
            This WordPress website serves as my solution to stay updated with
            the latest movies and TV shows.
          </p>
          <p>
            I've designed a bespoke theme that's not only responsive but also
            accessible. To build the front-end, I utilized SASS and followed the
            BEM methodology.
          </p>
          <p>
            All data is sourced from The Movie Database (TMDB) API. I've also
            created a custom post type for movies and TV shows, complete with
            custom fields using ACF.
          </p>
        </>
      ),
      tech: ["Wordpress", "SASS", "ACF", "API"],
      link: "https://trailer-break.com",
    },
    {
      icon: charitiesIcon,
      cover: charities,
      title: "Charitiesnearme.uk",
      slogan: "Discover the charities that matter most to you",
      description: (
        <>
          <p>
            There are around 200,000 charities in the UK. Charitiesnearme.uk is
            the perfect tool to find the ones you really want to help.
          </p>
          <p>
            At the core of this website is a tailor-made, responsive, and
            accessible theme, designed to provide a seamless user experience.
          </p>
          <p>
            To provide you with the most up-to-date charity data, I've harnessed
            the power of the Charity Commission API and integrated Google Maps.
          </p>
          <p>
            To keep the website organized and user-friendly, I've implemented
            custom post types and Advanced Custom Fields (ACF).
          </p>
          <p>
            And for those who want to stay engaged and connected with their
            favorite charities, I've integrated Aweber for email marketing. Now
            you can receive updates, news, and more, right in your inbox.
          </p>
        </>
      ),
      tech: ["Wordpress", "SASS", "ACF", "API", "Aweber"],
      link: "https://charitiesnearme.uk",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="portfolio ">
      <h2
        className="portfolio-title primary-title gradient__text section__padding"
        id="portfolio"
      >
        Portfolio
      </h2>
      <div className="portfolio-nav ">
        {portfolioStock.map((site, i) => {
          return (
            <div
              key={site.title}
              className="portfolio-item"
              onClick={() => setCurrentIndex(i)}
            >
              <img src={site.icon} alt="item-icon" />
              <h3
                className={
                  currentIndex === i
                    ? "portfolio-item-title active"
                    : "portfolio-item-title"
                }
              >
                {site.title}
              </h3>
            </div>
          );
        })}
      </div>

      <div className="portfolio-showcase">
        <img src={portfolioStock[currentIndex].cover} alt="Website cover" />
        <div className="portfolio-showcase-nav">
          {portfolioStock.map((site, i) => {
            return (
              <div
                key={site.title + i}
                className={
                  currentIndex === i
                    ? "portfolio-showcase-dot active"
                    : "portfolio-showcase-dot"
                }
                onClick={() => setCurrentIndex(i)}
              ></div>
            );
          })}
        </div>
      </div>
      <div
        className="portfolio-showcase-infos section__margin section__padding"
        id=""
      >
        <div className="portfolio-showcase-content">
          <h2 className="portfolio-showcase-title primary-title gradient__text">
            {portfolioStock[currentIndex].title}
          </h2>
          <h3 className="portfolio-showcase-subtitle secondary-title">
            {portfolioStock[currentIndex].slogan}
          </h3>
          <>{portfolioStock[currentIndex].description}</>
        </div>
        <div className="portfolio-showcase-details">
          <Link
            to={portfolioStock[currentIndex].link}
            className="portfolio-btn"
            target="_blank"
          >
            VIEW LIVE SITE
          </Link>
          <div className="portfolio-showcase-tech">
            {portfolioStock[currentIndex].tech.map((tech) => {
              return <h3 key={tech}>{tech}</h3>;
            })}
          </div>
        </div>
      </div>
      <div className="bubble-bg"></div>
    </div>
  );
};

export default Portfolio;
