import webDesignImg from "../assets/web-design-services-524.webp";
import webDevImg from "../assets/web-development-services-524.webp";
import seoImg from "../assets/seo-services-524.webp";
import socialImg from "../assets/social-media-services-524.webp";

import figma from "../assets/skills/figma-logo.png";
import xd from "../assets/skills/xd-logo.png";
import gimp from "../assets/skills/gimp-logo.png";
import html from "../assets/skills/html-logo.png";
import css from "../assets/skills/css-logo.png";
import sass from "../assets/skills/sass-logo.png";
import js from "../assets/skills/js-logo.png";
import typescript from "../assets/skills/typescript-logo.png";
import react from "../assets/skills/react-logo.png";
import redux from "../assets/skills/redux-logo.png";
import node from "../assets/skills/node-logo.png";
import mysql from "../assets/skills/mysql-logo.png";
import mongo from "../assets/skills/mongo-logo.png";
import github from "../assets/skills/github-logo.png";
import wordpress from "../assets/skills/wordpress-logo.png";
import ahrefs from "../assets/skills/ahrefs-logo.png";
import gganalytics from "../assets/skills/gg-analytics-logo.png";
import ggsearch from "../assets/skills/gg-search-logo.png";
import pagespeed from "../assets/skills/pagespeed-logo.png";
import screamingfrog from "../assets/skills/screamingfrog-logo.png";
import semrush from "../assets/skills/semrush-logo.png";
import facebook from "../assets/skills/facebook-logo.png";
import instagram from "../assets/skills/instagram-logo.png";
import linkedin from "../assets/skills/linkedin-logo.png";
import twitter from "../assets/skills/twitter-logo.png";

const Services = () => {
  return (
    <div className="services section__padding" id="services">
      <h2 className="service-title primary-title gradient__text center">
        What can I do for you?
      </h2>

      {/*  DESIGN */}
      <div className="service row">
        <div className="service-image">
          <img
            src={webDesignImg}
            alt="Digital-partner.co.uk web design services"
          />
        </div>
        <div className="service-content">
          <h2 className="service-category gradient__text">DESIGN</h2>
          <h2 className="secondary-title">Designing digital success</h2>
          <p>
            I collaborate closely with you to create tailor-made solutions that
            align with your brand and objectives.
          </p>
          <p>
            From captivating web design to intuitive UI/UX, I craft engaging
            digital experiences that leave a lasting impact. With in-depth
            market research and a user-centric approach, I ensure your website
            stands out and delivers results.
          </p>
          <p>
            Elevate your online presence and drive meaningful engagement with
            Digital Partner, your strategic design ally.
          </p>
        </div>
        <div className="bubble-bg"></div>
      </div>
      <div className="service-techs">
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={figma} alt="figma" />
          </div>
          <p className="service-tech-title">Figma</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={xd} alt="figma" />
          </div>
          <p className="service-tech-title">XD</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={gimp} alt="figma" />
          </div>
          <p className="service-tech-title">Gimp</p>
        </div>
      </div>

      {/*  DEV */}
      <div className="service reverse">
        <div className="service-image">
          <img
            src={webDevImg}
            alt="Digital-partner.co.uk web development services"
          />
        </div>
        <div className="service-content">
          <h2 className="service-category gradient__text">DEVELOPMENT</h2>
          <h2 className="secondary-title">
            Empowering Web Development Excellence
          </h2>
          <p>
            Harnessing the power of diverse technologies, I deliver cutting-edge
            web solutions tailored to your needs.
          </p>
          <p>
            With meticulous coding and optimization, I ensure high-performance,
            scalable, and secure websites. Elevate your online presence with
            Digital Partner, your trusted ally in web development.
          </p>
          <p>Let's achieve digital excellence together!</p>
        </div>
        <div className="bubble-bg"></div>
      </div>
      <div className="service-techs service-techs-dev">
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={html} alt="HTML" />
          </div>
          <p className="service-tech-title">HTML</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={css} alt="CSS" />
          </div>
          <p className="service-tech-title">CSS</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={sass} alt="SASS" />
          </div>
          <p className="service-tech-title">SASS</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={js} alt="JS" />
          </div>
          <p className="service-tech-title">JS</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={typescript} alt="Typescript" />
          </div>
          <p className="service-tech-title">Typescript</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={react} alt="React" />
          </div>
          <p className="service-tech-title">React</p>
        </div>
      </div>
      <div className="service-techs">
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={redux} alt="Redux" />
          </div>
          <p className="service-tech-title">Redux</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={node} alt="Node" />
          </div>
          <p className="service-tech-title">Node</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={mysql} alt="MySQL" />
          </div>
          <p className="service-tech-title">MySQL</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={mongo} alt="MongoDB" />
          </div>
          <p className="service-tech-title">MongoDB</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={github} alt="Github" />
          </div>
          <p className="service-tech-title">Github</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={wordpress} alt="Wordpress" />
          </div>
          <p className="service-tech-title">Wordpress</p>
        </div>
      </div>

      {/*  SEO */}
      <div className="service row">
        <div className="service-image">
          <img src={seoImg} alt="Digital-partner.co.uk SEO services" />
        </div>
        <div className="service-content">
          <h2 className="service-category gradient__text">SEO</h2>
          <h2 className="secondary-title">SEO Success Unleashed</h2>
          <p>
            Drive targeted traffic, boost visibility. My expert SEO services
            optimize your website for higher search rankings.
          </p>
          <p>
            From comprehensive keyword research to authoritative backlinks, stay
            ahead of the competition. Maximize organic search performance with
            my holistic approach.
          </p>
          <p>
            Elevate your online presence with Digital Partner, your trusted SEO
            ally. Let me lead the way while you focus on business growth.
          </p>
        </div>
        <div className="bubble-bg"></div>
      </div>
      <div className="service-techs">
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={screamingfrog} alt="screamingfrog" />
          </div>
          <p className="service-tech-title">Screaming Frog</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={ahrefs} alt="ahrefs" />
          </div>
          <p className="service-tech-title">Ahrefs</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={semrush} alt="semrush" />
          </div>
          <p className="service-tech-title">Semrush</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={pagespeed} alt="pagespeed" />
          </div>
          <p className="service-tech-title">Speed Insights</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={gganalytics} alt="gganalytics" />
          </div>
          <p className="service-tech-title">Analytics</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={ggsearch} alt="ggsearch" />
          </div>
          <p className="service-tech-title">Search Console</p>
        </div>
      </div>

      {/*  SOCIAL */}
      <div className="service reverse">
        <div className="service-image">
          <img
            src={socialImg}
            alt="Digital-partner.co.uk social media services"
          />
        </div>
        <div className="service-content">
          <h2 className="service-category gradient__text">SOCIAL MEDIA</h2>
          <h2 className="secondary-title">Ignite Social Media Impact</h2>
          <p>
            Amplify your brand's online presence with my expert social media
            services.
          </p>
          <p>
            Engage your audience and drive meaningful results through tailored
            strategies. From content creation to community management, I
            optimize your social media performance using cutting-edge tools and
            insights.
          </p>
          <p>
            Stay ahead of the trends with Digital Partner as your trusted ally.
            Ignite your social media presence while focusing on business growth.
          </p>
        </div>
        <div className="bubble-bg"></div>
      </div>
      <div className="service-techs social">
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={facebook} alt="facebook" />
          </div>
          <p className="service-tech-title">Facebook</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={instagram} alt="instagram" />
          </div>
          <p className="service-tech-title">Instagram</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={twitter} alt="twitter" />
          </div>
          <p className="service-tech-title">Twitter</p>
        </div>
        <div className="service-tech">
          <div className="service-tech-bg">
            <img src={linkedin} alt="linkedin" />
          </div>
          <p className="service-tech-title">Linkedin</p>
        </div>
      </div>
    </div>
  );
};

export default Services;
