import React, { useState } from "react";
import logo from "../assets/logo-digital-partner-512.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(null);

  function showMenu() {
    toggleMenu === null ? setToggleMenu(true) : setToggleMenu(!toggleMenu);
  }

  const burgerStyle = toggleMenu === null ? "" : toggleMenu ? "show" : "noshow";

  return (
    <>
      <header className="header">
        <Link to="/" className="header-logo">
          <img src={logo} className="header-img" alt="Digital Partner logo" />
          <span className="header-title">digital-partner.co.uk</span>
        </Link>
        <nav className="header-nav">
          <div className={"header-links " + burgerStyle}>
            <Link to="/" className="header-link">
              Home
            </Link>
            <HashLink to="/#services" className="header-link">
              Services
            </HashLink>
            <HashLink to="/#portfolio" className="header-link">
              Portfolio
            </HashLink>
            <HashLink to="/#blog" className="header-link">
              Blog
            </HashLink>
            <button
              to="/contact"
              className="header-btn"
              onClick={(e) => {
                window.location.href = "mailto:hello@digital-partner.co.uk";
                e.preventDefault();
              }}
            >
              Contact
            </button>
          </div>
          <div className={"header-hamburger " + burgerStyle} onClick={showMenu}>
            <div className="line-1"></div>
            <div className="line-2"></div>
            <div className="line-3"></div>
          </div>
        </nav>
        <div className="bubble-bg"></div>
      </header>
    </>
  );
};

export default Header;
