import logo from "../assets/logo-digital-partner-512.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="footer-links">
        <Link to="/" className="footer-links_logo">
          <img src={logo} className="header-img" alt="Digital Partner logo" />
          <span className="header-title">digital-partner.co.uk</span>
        </Link>

        <HashLink to="#top" className="footer-link">
          Home
        </HashLink>
        <HashLink to="#services" className="footer-link">
          Services
        </HashLink>
        <HashLink to="#portfolio" className="footer-link">
          Portfolio
        </HashLink>
        <HashLink to="#blog" className="footer-link">
          Blog
        </HashLink>
        <Link to="contact" className="footer-btn">
          Contact
        </Link>
      </div>
      <div className="footer-copyright">
        <p>{year} digital-partner.co.uk All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
