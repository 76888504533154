import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Article from "../components/Article";
import Spinner from "../components/Spinner";

const Blog = () => {
  const [posts, setPosts] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch("https://digitalpartner-api.onrender.com/post")
      .then((response) => {
        response.json().then((posts) => {
          setLoading(false);
          setPosts(posts);
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  if (posts.length > 0 && !loading) {
    const [firstPost, ...otherPosts] = posts;

    return (
      <div className="blog section__padding" id="blog">
        <h2 className="blog-title primary-title gradient__text">Blog</h2>
        <div className="blog-container">
          <div className="blog-container_groupA">
            <Link to={"/" + firstPost.slug}>
              <Article
                id={firstPost._id}
                imgUrl={firstPost.cover}
                title={firstPost.title}
                excerpt={firstPost.excerpt}
              />
            </Link>
          </div>
          <div className="blog-container_groupB">
            {otherPosts.map((post) => {
              return (
                <Link to={"/" + post.slug} key={post._id}>
                  <Article imgUrl={post.cover} title={post.title} />
                </Link>
              );
            })}
          </div>
        </div>
        <div className="bubble-bg"></div>
      </div>
    );
  } else {
    return <Spinner />;
  }
};

export default Blog;
