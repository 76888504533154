import { useState } from "react";
import Editor from "../components/Editor";
import "react-quill/dist/quill.snow.css";
import { Navigate } from "react-router-dom";

export default function CreatePost() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [tags, setTags] = useState("");
  const [slug, setSlug] = useState("");
  const [files, setFiles] = useState("");
  const [redirect, setRedirect] = useState(false);

  async function createNewPost(e) {
    const data = new FormData();
    data.set("title", title);
    data.set("file", files[0]);
    data.set("content", content);
    data.set("metaTitle", metaTitle);
    data.set("metaDescription", metaDescription);
    data.set("excerpt", excerpt);
    data.set("tags", tags);
    data.set("slug", slug);
    e.preventDefault();

    const response = await fetch(
      "https://digitalpartner-api.onrender.com/post",
      {
        method: "POST",
        body: data,
        credentials: "include",
      }
    );

    if (response.ok) {
      setRedirect(true);
    }
  }

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  return (
    <div className="create-post section__padding">
      <h1 className="primary-title gradient__text">Let's be creative!</h1>
      <form onSubmit={createNewPost} className="create-post-form">
        <input
          type="title"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <input type="file" onChange={(e) => setFiles(e.target.files)} />
        <Editor value={content} onChange={setContent} />
        <input
          type="meta-title"
          placeholder="Meta title"
          value={metaTitle}
          onChange={(e) => setMetaTitle(e.target.value)}
        />
        <input
          type="meta-description"
          placeholder="Meta description"
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
        />
        <input
          type="excerpt"
          placeholder="Excerpt"
          value={excerpt}
          onChange={(e) => setExcerpt(e.target.value)}
        />
        <input
          type="tags"
          placeholder="Tags"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
        />
        <input
          type="slug"
          placeholder="Slug"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        />
        <button className="create-post-submit-btn">Create post</button>
      </form>
    </div>
  );
}
