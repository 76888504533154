import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../UserContext";
import { Link } from "react-router-dom";
import CTA from "../components/CTA.js";
import Article from "../components/Article.js";

export default function PostPage() {
  const { id } = useParams();
  const [postInfo, setPostInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allPosts, setAllPosts] = useState([]);
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    fetch("https://digitalpartner-api.onrender.com/" + id).then((response) => {
      response.json().then((postInfo) => {
        setPostInfo(postInfo[0]);
        setLoading(false);
      });
    });
  }, [id]);

  useEffect(() => {
    fetch("https://digitalpartner-api.onrender.com/post").then((response) => {
      response.json().then((posts) => {
        setAllPosts(posts.filter((post) => post.slug !== id));
      });
    });
  }, [id]);

  if (!postInfo) return "";

  return (
    <>
      {!loading ? (
        <>
          <div className="post-page">
            <h1 className="post-page-title primary-title gradient__text">
              {postInfo.title}
            </h1>

            {postInfo.author && userInfo.id === postInfo.author._id && (
              <div className="edit-row">
                <Link className="edit-btn" to={`/edit/${id}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                  Edit this post
                </Link>
              </div>
            )}

            <div className="post-page-image">
              <img
                src={`https://digitalpartner-api.onrender.com/${postInfo.cover}`}
                alt={postInfo.title}
              />
            </div>

            <div
              className="post-page-content"
              dangerouslySetInnerHTML={{ __html: postInfo.content }}
            />
            <div className="bubble-bg"></div>
          </div>
          <CTA title="Ready for innovation? Hire me, the self-taught coding enthusiast, for passion, adaptability, and fresh perspectives!" />

          <div className="post-page post-page-more">
            <h2 className="post-page-more__title primary-title gradient__text">
              Curious for more?
            </h2>
            {allPosts.map((post) => {
              return (
                <Link
                  to={"/" + post.slug}
                  key={post._id}
                  className="post-page-more__link"
                >
                  <Article
                    id={post._id}
                    imgUrl={post.cover}
                    title={post.title}
                    excerpt={post.metaDescription}
                  />
                </Link>
              );
            })}
            <div className="bubble-bg"></div>
          </div>
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
}
