import React from "react";
import heroImage from "../assets/hero-image-524.webp";
const Hero = () => {
  return (
    <div className="hero section__padding" id="home">
      <div className="hero-content">
        <h1 className="primary-title gradient__text">
          Transforming ideas into digital realities
        </h1>
        <p>
          Hi, I'm Guillaume, an experienced web developer specializing in web
          design, development, SEO and social media. With 10+ years of
          experience, I excel in creating visually appealing websites with
          robust functionalities
        </p>
        <p>
          I optimize sites for search engines and leverage social media for
          enhanced online presence. Explore my portfolio and let's discuss how I
          can be your reliable digital partner.
        </p>
      </div>
      <div className="hero-image">
        <img src={heroImage} alt="digital partner robot face" />
      </div>
    </div>
  );
};

export default Hero;
