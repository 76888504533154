import Hero from "../components/Hero";
import CTA from "../components/CTA";
import Blog from "../containers/Blog";
import Services from "../containers/Services";
import Portfolio from "../containers/Portfolio";

export default function IndexPage() {
  const firstCta =
    "Don't miss out on the opportunity to boost your online success. Reach out to me and let's get started!";
  const secondCta =
    "Get in touch today and let's discuss how we can elevate your digital presence!";
  const thirdCta =
    "Need expert guidance for your digital strategy? Contact me today and let's create a winning plan...";

  return (
    <>
      <Hero />
      <Services />
      <CTA title={firstCta} />
      <Portfolio />
      <CTA title={secondCta} />
      <Blog />
      <CTA title={thirdCta} />
    </>
  );
}
