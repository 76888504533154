import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Editor from "../components/Editor";

export default function EditPost() {
  const { id } = useParams();
  const [postId, setPostId] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [tags, setTags] = useState("");
  const [slug, setSlug] = useState("");
  const [files, setFiles] = useState("");
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    fetch("https://digitalpartner-api.onrender.com/" + id).then((response) => {
      response.json().then((postInfo) => {
        setPostId(postInfo[0]._id);
        setTitle(postInfo[0].title);
        setContent(postInfo[0].content);
        setMetaTitle(postInfo[0].metaTitle);
        setMetaDescription(postInfo[0].metaDescription);
        setExcerpt(postInfo[0].excerpt);
        setTags(postInfo[0].tags);
        setSlug(postInfo[0].slug);
      });
    });
  }, [id]);

  async function updatePost(e) {
    console.log(title);
    e.preventDefault();
    const data = new FormData();
    data.set("id", postId);
    data.set("title", title);
    data.set("content", content);
    data.set("metaTitle", metaTitle);
    data.set("metaDescription", metaDescription);
    data.set("excerpt", excerpt);
    data.set("tags", tags);
    data.set("slug", slug);
    if (files?.[0]) {
      data.set("file", files[0]);
    }
    console.log(data);
    const response = await fetch(
      "https://digitalpartner-api.onrender.com/post",
      {
        method: "PUT",
        body: data,
        credentials: "include",
      }
    );
    console.log(response);

    if (response.ok) {
      setRedirect(true);
    } else {
      console.log(response);
    }
  }
  if (redirect) {
    return <Navigate to={"/" + id} />;
  }

  return (
    <div className="create-post section__padding">
      <h1 className="primary-title gradient__text">Edit post</h1>
      <form onSubmit={updatePost} className="create-post-form">
        <input
          type="title"
          placeholder={"Title"}
          value={title}
          onChange={(e) => {
            console.log(e.target.value);
            setTitle(e.target.value);
          }}
        />
        <input type="file" onChange={(e) => setFiles(e.target.files)} />
        <Editor value={content} onChange={setContent} />
        <input
          type="meta-title"
          placeholder="Meta title"
          value={metaTitle}
          onChange={(e) => setMetaTitle(e.target.value)}
        />
        <input
          type="meta-description"
          placeholder="Meta description"
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
        />
        <input
          type="excerpt"
          placeholder="Excerpt"
          value={excerpt}
          onChange={(e) => setExcerpt(e.target.value)}
        />
        <input
          type="tags"
          placeholder="Tags"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
        />
        <input
          type="slug"
          placeholder="Slug"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        />
        <button className="create-post-submit-btn">Update post</button>
      </form>
    </div>
  );
}
