import React from "react";

const CTA = ({ title }) => {
  return (
    <div className="cta ">
      <h3 className="cta-title">{title}</h3>
      <div className="cta-btn">
        <button
          type="button"
          onClick={(e) => {
            window.location.href = "mailto:hello@digital-partner.co.uk";
            e.preventDefault();
          }}
        >
          CONTACT
        </button>
      </div>
    </div>
  );
};

export default CTA;
