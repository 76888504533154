const Article = ({ imgUrl, title, excerpt }) => (
  <>
    <div className="post-page-more__image">
      <img
        src={"https://digitalpartner-api.onrender.com/" + imgUrl}
        alt="blog_image"
      />
    </div>
    <div className="post-page-more__content">
      <div>
        <h3 className="post-page-more__title">{title}</h3>
        <p className="post-page-more__excerpt">{excerpt ? excerpt : ""}</p>
      </div>
      <p className="post-page-more__cta">Read Full Article </p>
    </div>
  </>
);

export default Article;
